import config from "../../../config";
const launchpadApps = [
  {
    id: "you54f.com",
    title: "you54f.com",
    img: "img/macos/icons/launchpad/flint.png",
    link: config.githubRepoUrl,
  },
];

export default launchpadApps;
