import React, { useState } from "react";
import SideBarApp from "../base/side_bar_app";

let renderApps = (props) => {
  let sideBarAppsJsx = [];
  props.apps.forEach((app, index) => {
    if (props.favourite_apps[app.id] === false) return;
    sideBarAppsJsx.push(
      <SideBarApp
        key={index}
        id={app.id}
        title={app.title}
        icon={app.icon}
        isClose={props.closed_windows}
        isFocus={props.focused_windows}
        openApp={props.openAppByAppId}
        isMinimized={props.isMinimized}
        openFromMinimised={props.openFromMinimised}
      />
    );
  });
  return sideBarAppsJsx;
};

export default function SideBar(props) {
  function showSideBar() {
    props.hideSideBar(null, false);
  }

  function hideSideBar() {
    setTimeout(() => {
      props.hideSideBar(null, true);
    }, 2000);
  }

  return (
    <>
      <div
        className={
          (props.hide ? " -translate-x-full " : "") +
          " absolute transform duration-300 select-none z-40 left-0 top-0 h-full pt-7 w-auto flex flex-col justify-start items-center border-black border-opacity-60 bg-black bg-opacity-50"
        }
      >
        {Object.keys(props.closed_windows).length !== 0
          ? renderApps(props)
          : null}
        <AllApps showApps={props.showAllApps} />
      </div>
      <div
        onMouseEnter={showSideBar}
        onMouseLeave={hideSideBar}
        className={"w-1 h-full absolute top-0 left-0 bg-transparent z-50"}
      ></div>
    </>
  );
}

export function AllApps(props) {
  const [title, setTitle] = useState(false);

  return (
    <div
      className={`absolute bottom-0 w-10 h-10 rounded m-1 hover:bg-white hover:bg-opacity-10 flex items-center justify-center`}
      onMouseEnter={() => {
        setTitle(true);
      }}
      onMouseLeave={() => {
        setTitle(false);
      }}
      onClick={props.showApps}
    >
      <img
        className="w-7"
        src="./themes/Yaru/system/view-app-grid-symbolic.svg"
        alt="Ubuntu view app"
      />
      <div
        className={
          (title ? " visible " : " invisible ") +
          " w-max py-0.5 px-1.5 absolute top-1 left-full ml-3 m-1 text-ubt-grey text-opacity-90 text-sm bg-ub-grey bg-opacity-70 border-gray-400 border border-opacity-40 rounded-md"
        }
      >
        Show Applications
      </div>
    </div>
  );
}
