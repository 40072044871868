import React, { Component } from "react";
import Draggable from "react-draggable";
import Settings from "../apps/settings";
import ReactGA from "react-ga";
import { displayTerminal } from "../apps/terminal";

export class Window extends Component {
  constructor() {
    super();
    this.id = null;
    this.startX = 60;
    this.startY = 10;
    this.state = {
      cursorType: "cursor-default",
      width: 60,
      height: 85,
      closed: false,
      maximized: false,
      parentSize: {
        height: 100,
        width: 100,
      },
    };
  }

  componentDidMount() {
    this.id = this.props.id;
    this.setDefaultWindowDimenstion();

    // google analytics
    ReactGA.pageview(`/${this.id}`);

    // on window resize, resize boundary
    window.addEventListener("resize", this.resizeBoundries);
  }

  componentWillUnmount() {
    ReactGA.pageview("/desktop");
    window.removeEventListener("resize", this.resizeBoundries);
  }

  setDefaultWindowDimenstion = () => {
    if (window.innerWidth < 640) {
      this.setState({ height: 60, width: 85 }, this.resizeBoundries);
    } else {
      this.setState({ height: 85, width: 60 }, this.resizeBoundries);
    }
  };

  resizeBoundries = () => {
    this.setState({
      parentSize: {
        height:
          window.innerHeight - //parent height
          window.innerHeight * (this.state.height / 100.0) - // this window's height
          28, // some padding
        width:
          window.innerWidth - // parent width
          window.innerWidth * (this.state.width / 100.0), //this window's width
      },
    });
  };

  changeCursorToMove = () => {
    this.focusWindow();
    if (this.state.maximized) {
      this.restoreWindow();
    }
    this.setState({ cursorType: "cursor-move" });
  };

  changeCursorToDefault = () => {
    this.setState({ cursorType: "cursor-default" });
  };

  handleVerticleResize = () => {
    this.setState({ height: this.state.height + 0.1 }, this.resizeBoundries);
  };

  handleHorizontalResize = () => {
    this.setState({ width: this.state.width + 0.1 }, this.resizeBoundries);
  };

  setWinowsPosition = () => {
    var r = document.querySelector("#" + this.id);
    var rect = r.getBoundingClientRect();
    r.style.setProperty(
      "--window-transform-x",
      rect.x.toFixed(1).toString() + "px"
    );
    r.style.setProperty(
      "--window-transform-y",
      (rect.y.toFixed(1) - 32).toString() + "px"
    );
  };

  checkOverlap = () => {
    var r = document.querySelector("#" + this.id);
    var rect = r.getBoundingClientRect();
    if (rect.x.toFixed(1) < 50) {
      // if this window overlapps with SideBar
      this.props.hideSideBar(this.id, true);
    } else {
      this.props.hideSideBar(this.id, false);
    }
  };

  focusWindow = () => {
    this.props.focus(this.id);
  };

  minimizeWindow = () => {
    let posx = -310;
    if (this.state.maximized) {
      posx = -510;
    }
    this.setWinowsPosition();
    // get corrosponding sidebar app's position
    var r = document.querySelector("#sidebar-" + this.id);
    var sidebBarApp = r.getBoundingClientRect();

    r = document.querySelector("#" + this.id);
    // translate window to that position
    r.style.transform = `translate(${posx}px,${
      sidebBarApp.y.toFixed(1) - 240
    }px) scale(0.2)`;
    this.props.hasMinimised(this.id);
  };

  restoreWindow = () => {
    var r = document.querySelector("#" + this.id);
    this.setDefaultWindowDimenstion();
    // get previous position
    let posx = r.style.getPropertyValue("--window-transform-x");
    let posy = r.style.getPropertyValue("--window-transform-y");

    r.style.transform = `translate(${posx},${posy})`;
    setTimeout(() => {
      this.setState({ maximized: false });
      this.checkOverlap();
    }, 300);
  };

  maximizeWindow = () => {
    if (this.state.maximized) {
      this.restoreWindow();
    } else {
      this.focusWindow();
      var r = document.querySelector("#" + this.id);
      this.setWinowsPosition();
      // translate window to maximize position
      r.style.transform = `translate(0px,-2pt)`;
      this.setState({ maximized: true, height: 95.5, width: 100 });
      this.props.hideSideBar(this.id, true);
    }
  };

  closeWindow = () => {
    this.setWinowsPosition();
    this.setState({ closed: true }, () => {
      this.props.hideSideBar(this.id, false);
      setTimeout(() => {
        this.props.closed(this.id);
      }, 300); // after 300ms this window will be unmounted from parent (Desktop)
    });
  };

  render() {
    return (
      <Draggable
        axis="both"
        handle=".bg-ub-window-title"
        grid={[1, 1]}
        scale={1}
        onStart={this.changeCursorToMove}
        onStop={this.changeCursorToDefault}
        onDrag={this.checkOverlap}
        allowAnyClick={false}
        defaultPosition={{ x: this.startX, y: this.startY }}
        bounds={{
          left: 0,
          top: 0,
          right: this.state.parentSize.width,
          bottom: this.state.parentSize.height,
        }}
      >
        <div
          style={{
            width: `${this.state.width}%`,
            height: `${this.state.height}%`,
          }}
          className={
            this.state.cursorType +
            " " +
            (this.state.closed ? " closed-window " : "") +
            (this.state.maximized
              ? " duration-300 rounded-none"
              : " rounded-lg rounded-b-none") +
            (this.props.minimized ? " opacity-0 invisible duration-200 " : "") +
            (this.props.isFocused ? " z-30 " : " z-20 notFocused") +
            " opened-window overflow-hidden min-w-1/4 min-h-1/4 main-window absolute window-shadow border-black border-opacity-40 border border-t-0 flex flex-col"
          }
          id={this.id}
        >
          <WindowYBorder resize={this.handleHorizontalResize} />
          <WindowXBorder resize={this.handleVerticleResize} />
          <WindowTopBar title={this.props.title} />
          <WindowEditButtons
            minimize={this.minimizeWindow}
            maximize={this.maximizeWindow}
            isMaximised={this.state.maximized}
            close={this.closeWindow}
            id={this.id}
          />
          {this.id === "settings" ? (
            <Settings
              changeBackgroundImage={this.props.changeBackgroundImage}
              currBgImgName={this.props.bg_image_name}
            />
          ) : (
            <WindowMainScreen
              screen={this.props.screen}
              title={this.props.title}
              addFolder={
                this.props.id === "terminal" ? this.props.addFolder : null
              }
              openApp={this.props.openApp}
            />
          )}
        </div>
      </Draggable>
    );
  }
}

export default Window;

// Window's title bar
export function WindowTopBar(props) {
  return (
    <div
      className={
        " relative bg-ub-window-title border-t-2 border-white border-opacity-5 py-1.5 px-3 text-white w-full select-none rounded-b-none"
      }
    >
      <div className="flex justify-center text-sm font-bold">{props.title}</div>
    </div>
  );
}

// Window's Borders
export class WindowYBorder extends Component {
  componentDidMount() {
    this.trpImg = new Image(0, 0);
    this.trpImg.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
    this.trpImg.style.opacity = 0;
  }
  render() {
    return (
      <div
        className=" window-y-border border-transparent border-1 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        onDragStart={(e) => {
          e.dataTransfer.setDragImage(this.trpImg, 0, 0);
        }}
        onDrag={this.props.resize}
      ></div>
    );
  }
}

export class WindowXBorder extends Component {
  componentDidMount() {
    this.trpImg = new Image(0, 0);
    this.trpImg.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
    this.trpImg.style.opacity = 0;
  }
  render() {
    return (
      <div
        className=" window-x-border border-transparent border-1 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        onDragStart={(e) => {
          e.dataTransfer.setDragImage(this.trpImg, 0, 0);
        }}
        onDrag={this.props.resize}
      ></div>
    );
  }
}

// Window's Edit Buttons
export function WindowEditButtons(props) {
  return (
    <div className="absolute select-none right-0 top-0 mt-1 mr-1 flex justify-center items-center">
      <span
        className="mx-1.5 bg-white bg-opacity-0 hover:bg-opacity-10 rounded-full flex justify-center mt-1 h-5 w-5 items-center"
        onClick={props.minimize}
      >
        <img
          src="./themes/Yaru/window/window-minimize-symbolic.svg"
          alt="ubuntu window minimize"
          className="inline"
        />
      </span>
      {props.isMaximised ? (
        <span
          className="mx-2 bg-white bg-opacity-0 hover:bg-opacity-10 rounded-full flex justify-center mt-1 h-5 w-5 items-center"
          onClick={props.maximize}
        >
          <img
            src="./themes/Yaru/window/window-restore-symbolic.svg"
            alt="ubuntu window restore"
            className="inline"
          />
        </span>
      ) : (
        <span
          className="mx-2 bg-white bg-opacity-0 hover:bg-opacity-10 rounded-full flex justify-center mt-1 h-5 w-5 items-center"
          onClick={props.maximize}
        >
          <img
            src="./themes/Yaru/window/window-maximize-symbolic.svg"
            alt="ubuntu window maximize"
            className="inline"
          />
        </span>
      )}
      <button
        tabIndex="-1"
        id={`close-${props.id}`}
        className="mx-1.5 focus:outline-none cursor-default bg-ub-orange bg-opacity-90 hover:bg-opacity-100 rounded-full flex justify-center mt-1 h-5 w-5 items-center"
        onClick={props.close}
      >
        <img
          src="./themes/Yaru/window/window-close-symbolic.svg"
          alt="ubuntu window close"
          className="inline"
        />
      </button>
    </div>
  );
}

// Window's Main Screen
export class WindowMainScreen extends Component {
  constructor() {
    super();
    this.state = {
      setDarkBg: false,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ setDarkBg: true });
    }, 3000);
  }
  render() {
    return (
      <div
        className={
          "w-full flex-grow z-20 max-h-full overflow-y-auto windowMainScreen" +
          (this.state.setDarkBg ? " bg-ub-drk-abrgn " : " bg-ub-cool-grey")
        }
      >
        {this.props.addFolder
          ? displayTerminal(this.props.addFolder, this.props.openApp)
          : this.props.screen()}
      </div>
    );
  }
}
